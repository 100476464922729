/* __________________________________ */
/* Grid mode */


/* Defining REMs to equal column width */
html {
  font-size:var(--column-width);
  background:var(--color-background);
}

/* sets up the layout grid. */
body.gridmode {
  background-size: 1rem .5em; /* The first number sets the width in columns (REMs), and the second the height in EMs */
  background-image: linear-gradient(to left, lightblue 0 1px, transparent 1px), linear-gradient(to bottom, lightblue 0 1px, transparent 2px); /* Grid for design work */
}

/* outline main layout elements in red */
body.gridmode * {
  outline: 1px solid red;
  outline-offset: -1px;
}

/* outline everything in the article body in blue */
body.gridmode .o-section-body * {
  outline-color:blue;
}

/* display labels */
body.gridmode .display-label:before {
  content:'Section';
  content: attr(data-display-label);
  position:absolute;
  font-size:calc(var(--fontsize-base)*0.75);
  font-family: sans-serif;
  font-weight:normal;
  line-height:1;
  text-transform:uppercase;
  background:red;
  color:white;
  padding:.25em .125em;
  margin-top:-1.5em;
}

/* display viewport width */
body.gridmode:before {
  content:    attr(viewport-width);
  position:   fixed;
  z-index:    1;
  font-size:  1em;
  top:        calc(1em/3);
  left:       calc(1em/3);
  font-size:  var(--fontsize-base);
  background: var(--color-black);
  color:      white;
  padding:    .25em .5em;
}
